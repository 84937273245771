import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

const pressPhotoQuery = graphql`
  {
    photos: datoCmsPressInfo {
      images {
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 100%;
  padding: 2rem 0;
  text-align: center;
  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

const PressPhotos = () => {
  const data = useStaticQuery(pressPhotoQuery);
  const { images } = data.photos;

  return (
    <Wrapper>
      <Helmet>
        <title>Press Photos</title>
      </Helmet>
      {images.map((img, i) => (
        <img key={i} alt={`Press ${i + 1}`} src={img.url} />
      ))}
    </Wrapper>
  );
};

export default PressPhotos;
